exports.components = {
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-digital-cards-kerry-severin-js": () => import("./../../../src/pages/digital-cards/kerry-severin.js" /* webpackChunkName: "component---src-pages-digital-cards-kerry-severin-js" */),
  "component---src-pages-digital-cards-meria-alexis-js": () => import("./../../../src/pages/digital-cards/meria-alexis.js" /* webpackChunkName: "component---src-pages-digital-cards-meria-alexis-js" */),
  "component---src-pages-healthcare-marketing-js": () => import("./../../../src/pages/healthcare-marketing.js" /* webpackChunkName: "component---src-pages-healthcare-marketing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-lets-chat-js": () => import("./../../../src/pages/lets-chat.js" /* webpackChunkName: "component---src-pages-lets-chat-js" */),
  "component---src-pages-social-media-packages-js": () => import("./../../../src/pages/social-media-packages.js" /* webpackChunkName: "component---src-pages-social-media-packages-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

